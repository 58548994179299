// NPM
import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';

// Interfaces
import { ApiContract } from './api.constants';
import type { AxiosRequestHeaders } from 'axios';
import type { IFormUpdateCampaign } from '@/types/IFormUpdateCampaign';
import type {
	HelpeeSignupFormData,
	SignupHelpeeData,
	AuthJwtData,
	RelationData,
	SupportAdminData,
	RelationMomentData,
	UserData,
	CampaignData,
	CampaignDetailsData,
	AllCampaignsData,
	UserProfilePictureData,
	UserCriteriaData,
	HelperRelationsData,
} from './api.constants';
import type {
	SignUpHelpeeUpdateExpectationRequestType,
	SignUpHelpeeUpdateTouchPointsRequestType,
} from '@magma-app/magma-types/sign-up-helpee';
import type {
	CampaignSignUpHelperFillCriteriaRequestType,
	CampaignSignUpHelperFillTouchPointsRequestType,
	CampaignSignUpHelperManagePhoneNumberRequestType,
	CampaignSignUpHelperManagePhoneNumberResponseType,
	CampaignSignUpHelperRegisterHelperByEmailRequestType,
	CampaignSignUpHelperUpdateInfoRequestType,
	CampaignSignUpHelperUpdateProfilePictureRequestType,
	CampaignSignUpHelperVerifyPhoneNumberCodeRequestType,
} from '@magma-app/magma-types/campaign-sign-up-helper';
import { findError, type ErrorKeysType } from '@magma-app/magma-errors';
import { useCommonStore } from '@/stores/common';

// Define the Axios instances for each API

const instances = {
	main: axios.create({
		baseURL: import.meta.env.VITE_MAGMA_API,
	}),
	userDashboard: axios.create({
		baseURL: import.meta.env.VITE_MAGMA_API_USER_DASHBOARD,
		withCredentials: true,
	}),
	helperSignUp: axios.create({
		baseURL: import.meta.env.VITE_MAGMA_API,
		withCredentials: true,
	}),
};

const serializeFormData = (payload: {
	[key: string]: string | number | FileList | File;
}) => {
	const formData = new FormData();

	for (const key in payload) {
		if (typeof payload[key] === 'string' || typeof payload[key] === 'number') {
			formData.append(key, `${payload[key]}`);
		} else if (payload[key] instanceof FileList) {
			for (const file of payload[key] as FileList) {
				formData.append(key, file);
			}
		} else if (payload[key] instanceof File) {
			formData.append(key, payload[key] as File);
		} else {
			formData.append(key, JSON.stringify(payload[key]));
		}
	}

	return formData;
};

// Request interceptors
instances.userDashboard.interceptors.request.use((config) => {
	let jwtToken = undefined;

	// Get the JWT token from the cookie or local storage if we are allowed
	try {
		jwtToken = Cookies.get('jwtToken') || localStorage.getItem('jwtToken');
	} catch (e) {
		console.log("We don't have access to the local storage or cookies");
	}

	if (jwtToken) {
		if (!config.headers) {
			config.headers = {} as AxiosRequestHeaders;
		} else if (config.url != '/signin') {
			config.headers.Authorization = `Bearer ${jwtToken}`;
		}
	}

	return config;
});

instances.helperSignUp.interceptors.request.use((config) => {
	let jwtToken = undefined;

	// Get the JWT token from the cookie or local storage if we are allowed
	try {
		jwtToken = Cookies.get('jwtToken') || localStorage.getItem('jwtToken');
	} catch (e) {
		console.log("We don't have access to the local storage or cookies");
	}

	if (jwtToken) {
		if (!config.headers) {
			config.headers = {} as AxiosRequestHeaders;
		} else if (config.url != '/signin') {
			config.headers.Authorization = `Bearer ${jwtToken}`;
		}
	}

	return config;
});

// Response interceptors
instances.main.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const data = error.response?.data as any;
		const errorType = data.error.type as string;
		const errorSubType = data.error.subtype as string;

		const err = findError(errorType as ErrorKeysType, errorSubType);

		if (!err) {
			return Promise.reject(error);
		}

		if (err.actionType === 'redirect' && err.redirectPath) {
			window.location.href = err.redirectPath;
		}

		if (err.actionType === 'toast') {
			const commonStore = useCommonStore();

			commonStore.setAlert({
				open: true,
				type: 'error',
				title: 'Error',
				description: err.message?.en || 'An error occurred',
			});
		}

		return Promise.reject(error);
	}
);

instances.userDashboard.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const data = error.response?.data as any;
		const errorType = data.error.type as string;
		const errorSubType = data.error.subtype as string;

		const err = findError(errorType as ErrorKeysType, errorSubType);

		if (!err) {
			return Promise.reject(error);
		}

		if (err.actionType === 'redirect' && err.redirectPath) {
			window.location.href = err.redirectPath;
		}

		if (err.actionType === 'toast') {
			const commonStore = useCommonStore();

			commonStore.setAlert({
				open: true,
				type: 'error',
				title: 'Error',
				description: err.message?.en || 'An error occurred',
			});
		}

		return Promise.reject(error);
	}
);

// Export the API factory for each endpoint
export default class Api {
	public static main() {
		return {
			auth: {
				signInAs: async (email: string, loginAs?: string) => {
					return await instances.main.get(
						`/auth/login?${
							loginAs ? `loginAs=${loginAs}&` : ''
						}email=${encodeURIComponent(email)}`
					);
				},

				signUpHelpee: async (payload: {
					campaignUuid?: string;
					email: string;
					organizationId?: number;
					source?: string;
				}): Promise<SignupHelpeeData> => {
					return await instances.main.post(
						ApiContract.main.routes.POST_SIGNUP_HELPEE_EMAIL,
						payload
					);
				},

				sendCodeRequest: async (payload: {
					phoneNumber: string;
					helpeeId: number;
				}): Promise<{ message: string }> => {
					return await instances.main.post(
						'/sign-up-helpee/phone/send-code',
						payload
					);
				},

				validateCode: async (payload: {
					helpeeId: number;
					code: string;
				}): Promise<{
					data: {
						message: string;
						error?: boolean;
						errorType?: string;
					};
				}> => {
					return await instances.main.post(
						'/sign-up-helpee/phone/verify-code',
						payload
					);
				},

				completeSignUpHelpee: async (payload: {
					campaignUuid: string;
					firstname: string;
					helpeeId: number | undefined;
					lastname: string;
					organizationId: number | undefined;
					phoneNumber: string;
					strongUserCriteria: {
						criteriaId: number;
						answerKey?: string;
						answerKeys?: string[];
					};
				}): Promise<SignupHelpeeData> => {
					return await instances.main.put(
						'/sign-up-helpee/main-information',
						payload
					);
				},

				addOptionalInfoSignUpHelpee: async (payload: {
					campaignUuid: string;
					helpeeId: number | undefined;
					organizationId: number | undefined;
					userCriteria?: {
						criteriaId: number;
						answerKey?: string;
						answerKeys?: string[];
					}[];
				}): Promise<SignupHelpeeData> => {
					return await instances.main.put(
						'/sign-up-helpee/other-criteria',
						payload
					);
				},

				getCampaignHelpeeSignUpData: async (campaignHelpeeUuid: string) => {
					return await instances.main.get(
						`/sign-up-helpee/qualification?campaignHelpeeUuid=${campaignHelpeeUuid}`
					);
				},

				sendExpectationSignUpHelpee: async (
					payload: SignUpHelpeeUpdateExpectationRequestType
				) => {
					return await instances.main.post(
						'/sign-up-helpee/expectation',
						payload
					);
				},

				sendTouchPointsSignUpHelpee: async (payload: {
					campaignHelpeeUuid: string;
					touchPoints: { touchPointId: number; value: string }[];
					acceptEmailNotifications: boolean;
					acceptPhoneNotifications: boolean;
				}) => {
					return await instances.main.post(
						'/sign-up-helpee/touch-points',
						payload
					);
				},
			},

			referral: {
				getReferredSignUpData: async (referredUuid: string) => {
					return await instances.main.get(
						`/sign-up-helpee/referred?referredUuid=${referredUuid}`
					);
				},

				getReferredSharingSignUpData: async (shareId: string) => {
					return await instances.main.get(`/sharing?shareId=${shareId}`);
				},

				sendReferredSignUpData: async (payload: {
					referredUuid: string;
					firstname: string;
					lastname: string;
					email: string;
					phoneNumber: string;
					strongUserCriteria: {
						criteriaId: string;
						answerKey?: string;
						answerKeys?: string[];
					};
				}) => {
					return await instances.main.post('/sign-up-helpee/referred', payload);
				},

				sendReferredSharingSignUpData: async (payload: {
					campaignUuid: string;
					helperId: number;
					shareId: string;
					firstname: string;
					lastname: string;
					email: string;
					phoneNumber: string;
					strongUserCriteria: {
						criteriaId: number;
						answerKey?: string;
						answerKeys?: string[];
					};
					helpeeTouchPoints: {
						touchPointId: string;
						value: string;
					}[];
				}) => {
					return await instances.main.post('/sign-up-helpee/sharing', payload);
				},
			},

			test: {
				testError: async () => {
					return await instances.main.get(`/error/random`);
				},
			},
		};
	}

	public static userDashboard() {
		return {
			auth: {
				getJwtToken: async (params: {
					token: string;
				}): Promise<AuthJwtData> => {
					return await instances.userDashboard.get(
						ApiContract.userDashboard.routes.GET_JWT_TOKEN,
						{
							params,
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						}
					);
				},

				getSignInData: async (
					organizationSubdomainName: string,
					campaignUuid: string
				) => {
					let params = '';

					if (campaignUuid) {
						params += `campaignUuid=${campaignUuid}`;
					} else if (organizationSubdomainName) {
						params += `organizationSubdomainName=${organizationSubdomainName}`;
					}

					try {
						return await instances.userDashboard.get(
							`/public/sign-in?${params}`
						);
					} catch (e) {
						return null;
					}
				},

				signIn: async (email: string) => {
					return await instances.userDashboard.get(
						`/auth/login?email=${encodeURIComponent(email)}`
					);
				},

				getHelpeeSignUpData: async (payload: {
					campaignUuid?: string;
					organizationUuid?: string;
				}): Promise<HelpeeSignupFormData> => {
					let params = '';

					if (payload.campaignUuid) {
						params += `campaignUuid=${payload.campaignUuid}`;
					} else if (payload.organizationUuid) {
						params += `organizationUuid=${payload.organizationUuid}`;
					}
					return await instances.userDashboard.get(
						`${ApiContract.userDashboard.routes.GET_HELPEE_SIGNUP}?${params}`
					);
				},
			},

			campaign: {
				getAllCampaigns: async () => {
					return await instances.userDashboard.get('/campaigns/helper');
				},

				getCampaigns: async (): Promise<CampaignDetailsData> => {
					return await instances.userDashboard.get(
						ApiContract.userDashboard.routes.GET_CAMPAIGN_HELPER_SIGNEDUP
					);
				},

				getCampaign: async (campaignUuid: string): Promise<CampaignData> => {
					return await instances.userDashboard.get(
						`${ApiContract.userDashboard.routes.GET_CAMPAIGN_HELPER}?campaignUuid=${campaignUuid}`,
						{
							params: {
								needCriteria: true,
								needMoments: true,
							},
						}
					);
				},

				getCampaignInfo: async (relationId: number) => {
					return await instances.userDashboard.get(`/campaigns/info`, {
						params: {
							relationId,
						},
					});
				},

				updateCampaign: async (
					payload: IFormUpdateCampaign
				): Promise<CampaignData> => {
					return await instances.userDashboard.put(
						`${
							ApiContract.userDashboard.routes.PUT_CAMPAIGN_HELPER_SETTINGS
						}?${qs.stringify(payload)}`
					);
				},

				joinCampaign: async (payload: {
					campaignId: number;
				}): Promise<AllCampaignsData> => {
					return await instances.userDashboard.post(
						`${
							ApiContract.userDashboard.routes.POST_CAMPAIGN_HELPER_JOIN
						}?${qs.stringify(payload)}`
					);
				},

				leaveCampaign: async (payload: {
					campaignId: number;
					optOutReason:
						| 'too-many-relations'
						| 'no-more-time'
						| 'doesnt-want'
						| 'no-more-in-org';
					keepUnstartedRelations: boolean | null;
					reactivationIn: 0 | 1 | 3 | 6 | null;
				}) => {
					return await instances.userDashboard.post(
						`/campaign-helper/leave?${qs.stringify(payload)}`
					);
				},
			},

			challenges: {
				getChallenges: async () => {
					return await instances.userDashboard.get(`/challenges`);
				},

				startChallenge: async (challengeId: number) => {
					return await instances.userDashboard.post(
						`/challenges/start/${challengeId}`
					);
				},

				accomplishChallenge: async (userChallengeId: number) => {
					return await instances.userDashboard.post(
						`/challenges/done/helper/${userChallengeId}`
					);
				},
			},

			relations: {
				helpee: {
					getRelation: async (
						relationId: number | undefined
					): Promise<RelationData> => {
						return await instances.userDashboard.get(
							`${ApiContract.userDashboard.routes.GET_HELPEE_RELATION}${
								relationId ? '?relationId=' + relationId : ''
							}`
						);
					},

					markAsDone: async (payload: {
						comment?: string;
						rating?: 1 | 2 | null;
						relationMomentId: number;
					}): Promise<RelationMomentData> => {
						return await instances.userDashboard.post(
							`${
								ApiContract.userDashboard.routes
									.POST_HELPEE_RELATION_MOMENT_DONE
							}?${qs.stringify(payload)}`
						);
					},

					markAsongoing: async (payload: { relationMomentId: number }) => {
						return await instances.userDashboard.post(
							`/relation-moments/helpee/ongoing?${qs.stringify(payload)}`
						);
					},

					reportHelper: async (payload: {
						reportScreenshot?: File | null;
						report: {
							askRematch?: boolean | null;
							contactedAt?: Date | null;
							comment: string;
							reason: 'ghosting' | 'no-interest' | 'other' | string;
							relationId: number;
						};
					}) => {
						return await instances.userDashboard.post(
							`/relation/helpee/report/helper`,
							{
								reportScreenshot: payload.reportScreenshot,
								report: JSON.stringify(payload.report),
							},
							{
								headers: {
									'Content-Type': 'multipart/form-data',
								},
							}
						);
					},
				},

				helper: {
					contactAdmin: async (payload: {
						file?: File | undefined;
						message: string;
						relationMomentId: number;
					}) => {
						return await instances.userDashboard.post(
							`/relation-moments/helper/message`,
							serializeFormData(payload),
							{
								headers: {
									'Content-Type': 'multipart/form-data',
								},
							}
						);
					},

					getRelation: async (relationId: number): Promise<RelationData> => {
						return await instances.userDashboard.get(
							`${ApiContract.userDashboard.routes.GET_RELATIONS_HELPER}/${relationId}`
						);
					},

					getRelations: async (): Promise<HelperRelationsData> => {
						return await instances.userDashboard.get(
							ApiContract.userDashboard.routes.GET_RELATIONS_HELPER
						);
					},

					markAsDone: async (payload: {
						comment?: string;
						rating?: 1 | 2 | null;
						relationMomentId: number;
					}): Promise<RelationMomentData> => {
						return await instances.userDashboard.post(
							`${
								ApiContract.userDashboard.routes
									.POST_RELATIONS_MOMENT_HELPER_DONE
							}?${qs.stringify(payload)}`
						);
					},

					markAsongoing: async (payload: {
						relationMomentId: number;
					}): Promise<RelationMomentData> => {
						return await instances.userDashboard.post(
							`${
								ApiContract.userDashboard.routes
									.POST_RELATIONS_MOMENT_HELPER_ONGOING
							}?${qs.stringify(payload)}`
						);
					},

					markAsRead: async (payload: {
						relationMomentId: number;
					}): Promise<RelationMomentData> => {
						return await instances.userDashboard.post(
							`${
								ApiContract.userDashboard.routes
									.POST_RELATIONS_MOMENT_HELPER_READ
							}?${qs.stringify(payload)}`
						);
					},

					reportHelpee: async (payload: {
						reportScreenshot?: File | null;
						report: {
							contactedAt?: Date | null;
							comment: string;
							reason: 'ghosting' | 'no-interest' | 'other' | string;
							relationId: number;
						};
					}) => {
						return await instances.userDashboard.post(
							`/relations/helper/report/helpee`,
							{
								reportScreenshot: payload.reportScreenshot,
								report: JSON.stringify(payload.report),
							},
							{
								headers: {
									'Content-Type': 'multipart/form-data',
								},
							}
						);
					},

					referHelpee: async (payload: {
						firstname: string;
						lastname: string;
						email: string;
						campaignId: number;
						phoneNumber?: string;
					}) => {
						return await instances.userDashboard.post(
							'/refer/helpee',
							qs.stringify(payload),
							{
								headers: {
									'Content-Type': 'application/x-www-form-urlencoded',
								},
							}
						);
					},
				},
			},

			rewards: {
				getRewards: async () => {
					return await instances.userDashboard.get(`/rewards`);
				},

				getPurchasedRewards: async () => {
					return await instances.userDashboard.get(`/rewards/purchased`);
				},

				purchaseReward: async (rewardId: number) => {
					return await instances.userDashboard.post(`/rewards/${rewardId}`);
				},
			},

			user: {
				getUser: async (
					needCriteria: boolean,
					needIsHelpee: boolean,
					needIsHelper: boolean,
					needPoints: boolean
				): Promise<UserData> => {
					return await instances.userDashboard.get(
						ApiContract.userDashboard.routes.GET_USER,
						{
							params: {
								needCriteria,
								needIsHelpee,
								needIsHelper,
								needPoints,
							},
						}
					);
				},

				uploadProfilePicture: async (payload: {
					profilePicture: File;
				}): Promise<UserProfilePictureData> => {
					return await instances.userDashboard.put(
						ApiContract.userDashboard.routes.PUT_USER_PROFILE_PICTURE,
						payload,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						}
					);
				},

				updateUser: async (payload: {
					firstname?: string;
					lastname?: string;
					phoneNumber?: string;
					notificationEmail?: string;
					acceptPhoneNotifications?: boolean | undefined;
					acceptEmailNotifications?: boolean | undefined;
				}): Promise<UserData> => {
					return await instances.userDashboard.put(
						`${ApiContract.userDashboard.routes.PUT_USER}?${qs.stringify(
							payload
						)}`,
						{
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						}
					);
				},

				getUserCriteria: async () => {
					return await instances.userDashboard.get(`/campaigns/user/criteria`);
				},

				updateUserCriteria: async (payload: {
					criteriaId: number;
					answerKey?: string;
					answerKeys?: string[];
				}): Promise<UserCriteriaData> => {
					return await instances.userDashboard.put(
						`${
							ApiContract.userDashboard.routes.PUT_USER_CRITERIA
						}?${qs.stringify(payload)}`,
						{
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						}
					);
				},

				getUserTouchpoints: async () => {
					return await instances.userDashboard.get(
						`/campaigns/user/touch-points`
					);
				},

				getHelpeeTouchpoints: async () => {
					return await instances.userDashboard.get(`/user-touchPoints`);
				},

				updateUserTouchpoint: async (payload: {
					touchPointId: number;
					value: string;
				}) => {
					return await instances.userDashboard.put(
						`/user-touchPoint`,
						payload,
						{
							headers: {
								'Content-Type': 'application/json',
							},
						}
					);
				},

				getSupportData: async (
					relationId?: number,
					campaignId?: number
				): Promise<SupportAdminData> => {
					return await instances.userDashboard.get(
						ApiContract.userDashboard.routes.GET_SUPPORT_ADMIN,
						{
							params: {
								relationId,
								campaignId,
							},
						}
					);
				},

				sendSupportMessage: async (payload: {
					message: string;
					campaignAdminUuid: string;
				}) => {
					return await instances.userDashboard.post(
						`/support/message`,
						qs.stringify(payload),
						{
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded',
							},
						}
					);
				},

				getSharingLink: async () => {
					return await instances.userDashboard.get(`/sharing`);
				},
			},

			helperCampaignSignUp: {
				getPublicCampaignData: async (campaignUuid: string) => {
					return await instances.helperSignUp.get(
						`/campaign-sign-up-helper/${campaignUuid}/public`
					);
				},

				getAuthCampaignData: async (campaignUuid: string) => {
					return await instances.helperSignUp.get(
						`/campaign-sign-up-helper/${campaignUuid}`
					);
				},

				createHelperWithEmail: async (
					payload: CampaignSignUpHelperRegisterHelperByEmailRequestType
				) => {
					return await instances.helperSignUp.post(
						`/campaign-sign-up-helper/email`,
						payload
					);
				},

				registerPhoneNumber: async (
					payload: CampaignSignUpHelperManagePhoneNumberRequestType
				) => {
					return await instances.helperSignUp.put(
						`/campaign-sign-up-helper/phone`,
						payload
					);
				},

				verifyCode: async (
					payload: CampaignSignUpHelperVerifyPhoneNumberCodeRequestType
				) => {
					return await instances.helperSignUp.post(
						'/campaign-sign-up-helper/phone/verify-code',
						payload
					);
				},

				updateInfo: async (
					payload: CampaignSignUpHelperUpdateInfoRequestType
				) => {
					return await instances.helperSignUp.put(
						'/campaign-sign-up-helper/info',
						payload
					);
				},

				updateProfilePicture: async (
					payload: CampaignSignUpHelperUpdateProfilePictureRequestType
				) => {
					return await instances.helperSignUp.put(
						'/campaign-sign-up-helper/profile-picture',
						payload,
						{
							headers: {
								'Content-Type': 'multipart/form-data',
							},
						}
					);
				},

				sendCriteria: async (
					payload: CampaignSignUpHelperFillCriteriaRequestType
				) => {
					return await instances.helperSignUp.post(
						'/campaign-sign-up-helper/criteria',
						payload
					);
				},

				sendTouchPoints: async (
					payload: CampaignSignUpHelperFillTouchPointsRequestType
				) => {
					return await instances.helperSignUp.post(
						'/campaign-sign-up-helper/touch-points',
						payload
					);
				},
			},
		};
	}
}
