<script setup lang="ts">
// NPM
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import BaseRewardChallengeDetails from '@/components/bases/BaseRewardChallengeDetails.vue';

// Components
import {
	BaseBadge,
	BaseEmptyState,
	FieldTabs,
	BaseCard,
} from '@magma-app/magma-lapilli';
import BaseRelation from '@/components/bases/BaseRelation.vue';
import TheSidebar from '@/components/shell/TheSidebar.vue';

// Types
import type { IRelation } from '@/types/IRelation';

// Stores
import { useRelationsStore } from '@/stores/relations';
import { useUserStore } from '@/stores/user';
import { useChallengesStore } from '@/stores/challenges';
import { useMq } from 'vue3-mq';
import type { IChallenge } from '@/types/IChallenge';
import { useCommonStore } from '@/stores/common';
const relationsStore = useRelationsStore();
const userStore = useUserStore();
const challengesStore = useChallengesStore();
const commonStore = useCommonStore();

// Plugins
const route = useRoute();
const router = useRouter();
const { t } = useI18n({ useScope: 'global' });
const mq = useMq();

// Data
const tabs = [
	{
		label: t('generic.toProcess'),
		value: 'toProcess',
	},
	{
		label: t('generic.ongoing'),
		value: 'ongoing',
	},
	{
		label: t('generic.finished'),
		value: 'finished',
	},
];

// Computed
const hasRelations = computed(() => {
	const relations = relationsStore.relations;

	return Boolean(
		relations.finished?.length ||
			relations.ongoing?.length ||
			relations.toProcess?.length
	);
});

const relations = computed(() => {
	if (currentTab.value === 'toProcess') {
		return relationsStore.relations.toProcess
			.slice()
			.sort((a, b) => b.daysLeft - a.daysLeft);
	} else if (currentTab.value === 'ongoing') {
		return relationsStore.relations.ongoing;
	} else if (currentTab.value === 'finished') {
		return relationsStore.relations.finished;
	} else {
		return [];
	}
});

const statuses: ('finished' | 'ongoing' | 'toProcess')[] = [
	'finished',
	'ongoing',
	'toProcess',
];

// Reactive variables
const challengeId = ref<number | null>(null);
const challengeOpen = ref(false);

// Computed
const challengeDetails = computed(() => {
	return challengesStore.uncompletedChallenges.find((challenge) => {
		return challenge.challengeId === challengeId.value;
	});
});

const onChallengeClick = (challenge: IChallenge) => {
	if (!mq.lgPlus && challenge.challengeId) {
		challengeOpen.value = true;
		challengesStore.setChallengeDetails(true);
		challengeId.value = challenge.challengeId;
		window.scrollTo(0, 0);
	} else if (mq.lgPlus) {
		commonStore.setManageChallengesRewardsModal({
			description: challenge.description,
			open: true,
			item: challenge,
			role: 'challenge',
			title: challenge.name,
		});
	}
};

// Reactive variables
const initialized = ref(false);
const currentTab = ref<'finished' | 'ongoing' | 'toProcess'>('toProcess');

// Lifecycle hooks
onBeforeMount(async () => {
	await relationsStore.getRelations();
	await challengesStore.getChallenges();

	if (route?.params.relationId) {
		for (const status of statuses) {
			if (findRelationStatus(status)) {
				currentTab.value = status;
				break;
			}
		}
	}

	initialized.value = true;
});

// Watchers
watch(route, async (newRoute) => {
	if (newRoute?.params.relationId) {
		for (const status of statuses) {
			if (findRelationStatus(status)) {
				currentTab.value = status;
				break;
			}
		}
	}
});

// Event Listeners
const onRelationClick = async (relation: IRelation) => {
	// Mark relation as read
	if (!relation.isRead) {
		await relationsStore.markRelationMomentAs({
			relationMomentId: relation.relationMomentId,
			role: 'read',
		});

		await relationsStore.getRelations();
	}

	// Go to relation
	router.push({
		name: 'helper-relation-overview',
		params: {
			relationId: relation.relationId,
		},
	});
};

const onCloseChallengeDetails = () => {
	challengesStore.setChallengeDetails(false);
	challengeOpen.value = false;
	challengeId.value = null;
};

// Methods
const findRelationStatus = (status: 'finished' | 'ongoing' | 'toProcess') => {
	return relationsStore.relations[status].some(
		(relation: IRelation) =>
			relation.relationId === parseInt(route?.params.relationId as string)
	);
};
</script>

<template>
	<div class="flex bg-background-subtle" data-cy="relations-layout">
		<TheSidebar />

		<div
			class="w-full flex-col bg-background-default pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:border-b-transparent lg:pt-8 2xl:ml-64"
			:class="[
				$route?.params.relationId ? 'hidden lg:flex' : 'flex',
				{ 'lg:w-[340px] 2xl:w-[400px]': hasRelations },
			]"
		>
			<div
				v-if="!challengeOpen"
				class="mb-4 flex items-center justify-between px-4 md:px-8 lg:mb-8"
			>
				<h2 class="text-2xl font-bold leading-7 text-foreground-emphasis">
					Relations
				</h2>

				<BaseBadge
					v-if="userStore.user?.organization?.pointEnabled"
					:text="userStore.user?.balance ? userStore.user?.balance : 0"
					size="md"
					icon-type="icon"
					icon-name="star"
				/>
			</div>

			<template v-if="hasRelations && !challengeOpen">
				<FieldTabs
					v-model="currentTab"
					:class="[$route?.params.relationId ? 'hidden lg:flex' : 'flex']"
					class="mx-4 mb-2 justify-between md:mx-8 md:justify-start lg:mb-4"
					:tabs="tabs"
					data-cy="relations-tab"
					@update:model-value="router.push({ name: 'relations-empty-state' })"
				/>

				<div
					class="flex-1"
					:class="{ 'hidden lg:block': $route?.params.relationId }"
				>
					<template v-if="relations?.length">
						<BaseRelation
							v-for="(relation, relationIndex) in relations"
							:key="relationIndex"
							data-cy="relation"
							:relation="relation"
							:active="
								relation.relationId ===
								parseInt($route?.params.relationId as string)
							"
							@click="onRelationClick(relation)"
						/>
					</template>

					<BaseEmptyState
						v-else
						class="flex h-full flex-col items-center justify-center"
						:text="$t(`relationsLayout.empty.${currentTab}`)"
						type="empty-list"
					/>
				</div>
			</template>

			<div v-else>
				<BaseEmptyState
					v-if="!challengeOpen"
					class="m-auto my-10"
					:text="$t('relationsLayout.emptyState')"
					type="awaiting-match"
				/>

				<div :class="`px-4 md:px-8 ${challengeOpen ? '' : 'mt-20'}`">
					<h4 v-if="!challengeOpen" class="text-mgm-txt-md font-medium">
						{{ $t(`relationsLayout.discoverChallenge.title`) }}
					</h4>
					<p
						v-if="!challengeOpen"
						class="text-mgm-txt-sm text-foreground-default mb-6"
					>
						{{ $t(`relationsLayout.discoverChallenge.subtitle`) }}
					</p>
					<div
						class="block h-full flex-1"
						:class="{
							'content-start lg:flex lg:flex-wrap':
								challengesStore.uncompletedChallenges?.length,
						}"
					>
						<template v-if="challengesStore.uncompletedChallenges?.length">
							<template v-if="!challengeDetails">
								<BaseCard
									v-for="(
										challenge, challengeIndex
									) in challengesStore.uncompletedChallenges"
									:key="challengeIndex"
									class="mb-2 lg:mb-6 lg:mr-6"
									:title="challenge.name"
									:description="challenge.description"
									:img-url="
										mq.lgPlus ? '/img/challenge_lg.png' : '/img/challenge.png'
									"
									:points="challenge.points"
									@click="onChallengeClick(challenge)"
								/>
							</template>

							<BaseRewardChallengeDetails
								v-else
								:item="challengeDetails as any"
								challenge
								@close="onCloseChallengeDetails"
							/>
						</template>
					</div>
				</div>
			</div>
		</div>

		<div
			v-if="hasRelations"
			class="w-full border border-border-subtle border-b-transparent border-l-transparent bg-background-default lg:mt-3"
			:class="{
				'hidden lg:block': !$route?.params.relationId,
				'items-start': $route?.params.relationId,
			}"
		>
			<routerView v-if="initialized" />
		</div>
	</div>
</template>
