<script setup lang="ts">
// NPM
import { computed, ref } from "vue";
import { useMq } from "vue3-mq";

// Components
import { BaseCard, BaseEmptyState } from "@magma-app/magma-lapilli";
import BaseRewardChallengeDetails from "@/components/bases/BaseRewardChallengeDetails.vue";

// Types
import type { IChallenge } from "@/types/IChallenge";

// Stores
import { useCommonStore } from "@/stores/common";
import { useChallengesStore } from "@/stores/challenges";
const commonStore = useCommonStore();
const challengesStore = useChallengesStore();

// Plugins
const mq = useMq();

// Reactive variables
const challengeId = ref<number | null>(null);

// Computed
const challengeDetails = computed(() => {
  return challengesStore.uncompletedChallenges.find((challenge) => {
    return challenge.challengeId === challengeId.value;
  });
});

// Methods
const onCloseChallengeDetails = () => {
  challengesStore.setChallengeDetails(false);
  challengeId.value = null;
};

const onChallengeClick = (challenge: IChallenge) => {
  if (!mq.lgPlus && challenge.challengeId) {
    challengesStore.setChallengeDetails(true);
    challengeId.value = challenge.challengeId;
    window.scrollTo(0, 0);
  } else if (mq.lgPlus) {
    commonStore.setManageChallengesRewardsModal({
      description: challenge.description,
      open: true,
      item: challenge,
      role: "challenge",
      title: challenge.name,
    });
  }
};
</script>

<template>
  <div
    class="block h-full flex-1 px-4 md:px-8"
    :class="{
      'content-start lg:flex lg:flex-wrap':
        challengesStore.uncompletedChallenges?.length,
    }"
  >
    <template v-if="challengesStore.uncompletedChallenges?.length">
      <template v-if="!challengeDetails">
        <BaseCard
          v-for="(
            challenge, challengeIndex
          ) in challengesStore.uncompletedChallenges"
          :key="challengeIndex"
          class="mb-2 lg:mb-6 lg:mr-6"
          :title="challenge.name"
          :description="challenge.description"
          :img-url="mq.lgPlus ? '/img/challenge_lg.png' : '/img/challenge.png'"
          :points="challenge.points"
          @click="onChallengeClick(challenge)"
        />
      </template>

      <BaseRewardChallengeDetails
        v-else
        :item="challengeDetails"
        challenge
        @close="onCloseChallengeDetails"
      />
    </template>

    <BaseEmptyState
      v-else
      class="flex h-full flex-col items-center justify-center"
      :text="$t('challengesView.empty.all')"
      type="empty-list"
    />
  </div>
</template>
