<script setup lang="ts">
import type { IUserCriteria } from '@/types/IUserCriteria';
import { onMounted } from 'vue';

const props = defineProps([
	'modelValue',
	'firstName',
	'bio',
	'criteria',
	'selectedCritria',
	'selectedTouchpoints',
	'profilePicture',
]);
const emit = defineEmits(['update:modelValue']);

const currentCriteriaAnswer = (id: number) => {
	const current = props.selectedCritria.find((c: IUserCriteria) => c.id === id);

	if (!current) {
		return '';
	}

	const answer = current?.answerKey;

	const answerOptions = current.options.find((o: any) => o.key === answer);

	return answerOptions?.value;
};

onMounted(() => {
	const input = document.querySelector('input');
	if (input) {
		input.focus();
	}
});
</script>

<template>
	<div class="">
		<h2 class="text-mgm-txt-xl font-medium">
			{{ $t('helperOnboarding.review.title') }}
		</h2>
		<h3 class="text-mgm-txt-sm text-foreground-default mb-6">
			{{ $t('helperOnboarding.review.subtitle') }}
		</h3>

		<div class="px-3">
			<div
				class="border border-border-subtle shadow-lg rounded-xl overflow-hidden max-w-80 mx-auto"
			>
				<div class="bg-background-subtle w-full h-72 relative">
					<span
						class="absolute top-3 right-3 bg-white/70 border border-border-on-emphasis rounded-full text-mgm-txt-xs px-2"
						>{{ $t('helperOnboarding.review.preview') }}</span
					>
					<img :src="profilePicture" class="object-cover w-full h-full" />
				</div>
				<div class="-mt-3 rounded-xl px-4 py-4 bg-white relative">
					<h3 class="text-mgm-txt-lg font-medium text-foreground-emphasis">
						{{ $t('helperOnboarding.review.meet') }} {{ firstName }}
					</h3>
					<h4 class="text-mgm-txt-sm text-foreground-default mb-4">
						{{ bio }}
					</h4>
					<div v-for="crit in criteria" :key="crit.id" class="mb-4">
						<p class="text-mgm-txt-sm text-foreground-default">
							{{ crit.label }}
						</p>
						<span class="text-mgm-txt-sm block">
							{{ currentCriteriaAnswer(crit.id) }}
						</span>
					</div>

					<div
						class="mb-4"
						v-if="Object.values(selectedTouchpoints).length > 0"
					>
						<p class="text-mgm-txt-sm text-foreground-default mb-1">
							Touchpoints
						</p>
						<div class="flex flex-wrap gap-2">
							<div
								v-for="touchpoint in Object.values(
									selectedTouchpoints
								) as unknown as any"
								class="h-8 rounded-full py-1.5 px-3 bg-background-subtle inline-flex items-center text-mgm-txt-sm gap-2 text-foreground-default font-medium"
							>
								<img class="w-4 h-auto" :src="touchpoint.icon" />
								{{ touchpoint.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
