<script setup lang="ts">
// NPM
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

// Components
import {
	BaseAvatar,
	BaseBadge,
	BaseButton,
	BaseIcon,
	BaseLoader,
	FieldToggle,
} from '@magma-app/magma-lapilli';
import HelperCampaignSignUpView from '@/views/HelperCampaignSignUpView.vue';
import TheSidebar from '@/components/shell/TheSidebar.vue';

// Helpers
import AnalyticsHelper from '@/helpers/analytics';

// Types
import type { IAllCampaigns } from '@/types/IAllCampaigns';

// Stores
import { useCommonStore } from '@/stores/common';
import { useUserStore } from '@/stores/user';
const commonStore = useCommonStore();
const userStore = useUserStore();

// Plugins
const route = useRoute();
const { t } = useI18n({ useScope: 'global' });

// Reactive variables
const awaitingMatch = ref(false);
const campaignToJoin = ref<IAllCampaigns | null>(null);
const initialized = ref(false);
const loadingStates = reactive<Record<number, boolean>>({}); // Represents loading state of each campaign toggle
const toggleStates = reactive<Record<number, boolean>>({}); // Represents toggle state of each campaign

// Computed
const sortedCampaigns = computed(() => {
	const sortedCampaigns = [...userStore.$state.allCampaigns];

	sortedCampaigns.sort((a, b) => {
		const statusOrder = {
			invited: 0,
			joined: 1,
			left: 2,
			archived: 3,
		};

		return statusOrder[a.helperStatus] - statusOrder[b.helperStatus];
	});

	return sortedCampaigns;
});

// Lifecycle hooks
onBeforeMount(async () => {
	await userStore.getAllCampaigns();

	// Initializing toggleStates for each campaign
	userStore.allCampaigns.forEach((campaign) => {
		toggleStates[campaign.id] = campaign.helperStatus === 'joined';
	});

	if (route?.query.reactivate === 'false') {
		commonStore.setManageRelationModal({
			closeCta: t('generic.getIt'),
			description: t('helperStatusView.refuse'),
			open: true,
			role: 'in-contact',
			title: t('generic.thanksUpdate'),
		});
	}

	if (route?.query.reactivate === 'true' && route?.query.campaign) {
		commonStore.setSlideOverModal({
			campaignId: parseInt(route?.query.campaign as string),
			statusHelperRole: 'reactivate-campaign',
			open: true,
		});
	}

	initialized.value = true;
});

watch(
	() => commonStore.$state.slideOverModal,
	(slideOverModal) => {
		if (
			!slideOverModal.props.open &&
			slideOverModal.props.closeType === 'cancel'
		) {
			toggleStates[slideOverModal.props.campaignId] =
				!toggleStates[slideOverModal.props.campaignId];
		}
	},
	{ deep: true }
);

// Event listeners
const onCampaignSignUp = () => {
	awaitingMatch.value = true;

	// Update toggleStates based on the current state in userStore
	userStore.allCampaigns.forEach((campaign) => {
		toggleStates[campaign.id] = campaign.helperStatus === 'joined';
	});
};

const onJoinCampaign = async (campaign: IAllCampaigns) => {
	if (campaign.helperStatus === 'invited') {
		campaignToJoin.value = campaign;
	} else {
		commonStore.setSlideOverModal({
			campaignId: campaign.id,
			statusHelperRole: 'reactivate-campaign',
			open: true,
		});
	}
};

const onLeaveCampaign = async (campaignId: number) => {
	commonStore.setSlideOverModal({
		campaignId: campaignId,
		statusHelperRole: 'deactivate-campaign',
		deactivateCampaignUnstartedRelations: userStore.allCampaigns.find(
			(campaign) => campaign.id === campaignId
		)?.relationsUnstartedCount,
		open: true,
	});
};

const onToggleChange = async (newValue: boolean, campaignId: number) => {
	awaitingMatch.value = false;
	loadingStates[campaignId] = true;

	const campaign = userStore.allCampaigns.find(
		(campaign) => campaign.id === campaignId
	);

	if (!campaign) {
		console.error(`Campaign with ID ${campaignId} not found`);
		loadingStates[campaignId] = false;
		return;
	}

	const { helperStatus } = campaign;

	try {
		if (newValue && helperStatus === 'left') {
			await onJoinCampaign(campaign);

			// Tracking event
			AnalyticsHelper.trackEvent('hs_reactivate_toggle', {
				user_properties: 'helper',
			});
		} else if (!newValue && helperStatus === 'joined') {
			await onLeaveCampaign(campaignId);

			// Tracking event
			AnalyticsHelper.trackEvent('hs_deactivate_toggle', {
				user_properties: 'helper',
			});
		}
	} catch (error) {
		console.log(error);
	} finally {
		loadingStates[campaignId] = false;
	}
};

// Methods
const campaignAvatars = (campaign: IAllCampaigns) => {
	let avatars: {
		url: string;
		tooltip?: string;
	}[] = [];

	if (
		campaign.helperStatus === 'invited' &&
		campaign.helpersProfilePicture?.length > 0
	) {
		avatars = campaign.helpersProfilePicture.map((helper) => ({
			url: helper,
		}));
	} else if (
		['left', 'joined'].includes(campaign.helperStatus) &&
		campaign.relations?.length > 0
	) {
		avatars = campaign.relations
			.filter((relation) => relation.profilePictureUrl)
			.map((relation) => ({
				url: relation.profilePictureUrl,
				tooltip: `${relation.firstname} ${relation.lastname}`,
			}));
	}

	if (avatars?.length > 4) {
		return avatars.slice(0, 4);
	} else {
		return avatars;
	}
};

const campaignStatus = (helperStatus: string, status: string) => {
	if (status === 'archived') {
		return {
			icon: 'archive',
			label: t('helperStatusView.helper.archived'),
		};
	} else {
		if (helperStatus === 'invited') {
			return {
				icon: 'mail',
				label: t('helperStatusView.helper.invited'),
			};
		} else if (helperStatus === 'joined') {
			return {
				icon: 'check-circle',
				label: t('helperStatusView.helper.joined'),
			};
		} else if (helperStatus === 'left') {
			return {
				icon: 'slash',
				label: t('helperStatusView.helper.left'),
			};
		} else {
			return {
				icon: undefined,
				label: '',
			};
		}
	}
};
</script>

<template>
	<div class="relative flex bg-background-subtle">
		<TheSidebar />

		<div
			class="w-full flex-1 bg-background-default pt-2 lg:ml-20 lg:mt-3 lg:rounded-tl-[32px] lg:border lg:border-border-subtle lg:pt-8 2xl:ml-64"
		>
			<div v-if="initialized" class="mx-auto mb-4 h-full lg:mb-8 lg:w-[704px]">
				<HelperCampaignSignUpView
					v-if="campaignToJoin"
					:campaign-to-join="campaignToJoin"
					@close="campaignToJoin = null"
					@notify="onCampaignSignUp"
				/>

				<div v-else class="px-4 pb-8 md:px-8">
					<h2 class="text-mgm-dspl-xs font-medium text-foreground-emphasis">
						{{ $t('generic.helperStatus') }}
					</h2>

					<p class="mt-2 text-mgm-txt-sm font-normal text-foreground-default">
						{{ $t('helperStatusView.status.description') }}
					</p>

					<p
						v-if="awaitingMatch"
						class="gradient__blue__01 mt-8 box-border rounded-[5px] p-4 text-foreground-on-emphasis shadow-[0px_1px_2px_rgba(14,16,56,0.4),0px_0px_0px_1px_rgba(6,16,204,0.76)]"
					>
						<span class="flex items-center">
							<BaseIcon class="mr-2" color="#FFFFFF" icon="clock" />

							<span class="block text-mgm-txt-sm font-medium">
								{{ $t('helperStatusView.wait') }}
							</span>
						</span>

						<span
							class="mt-1 block text-mgm-txt-sm font-normal text-border-subtle"
						>
							{{ $t('helperStatusView.patient') }}

							<RouterLink
								class="underline"
								:to="{
									name: 'relations',
								}"
							>
								{{ $t('helperStatusView.dedicated') }}
							</RouterLink>
						</span>
					</p>

					<div
						v-for="campaign in sortedCampaigns"
						:key="campaign.id"
						v-tooltip.top-end="
							campaign.status === 'archived'
								? $t('helperStatusView.archived')
								: undefined
						"
						:class="[
							'campaign-card mt-8 box-border w-full rounded-[5px] border border-border-subtle p-4 lg:pb-8',
							{
								'bg-background-subtle-hover': campaign.status === 'archived',
							},
						]"
					>
						<div
							class="flex w-full items-center justify-between border-b border-b-border-subtle pb-4"
						>
							<div class="flex w-full items-center">
								<BaseIcon
									class="mr-2"
									:icon="
										campaignStatus(campaign.helperStatus, campaign.status)
											.icon || ''
									"
									color="#5F6C85"
								/>

								<p class="text-mgm-txt-sm font-normal text-foreground-default">
									{{
										campaignStatus(campaign.helperStatus, campaign.status).label
									}}
								</p>
							</div>

							<FieldToggle
								v-if="campaign.helperStatus !== 'invited'"
								:id="campaign.id"
								v-model="toggleStates[campaign.id]"
								:disabled="
									campaign.status === 'archived' || loadingStates[campaign.id]
								"
								@change="onToggleChange"
							/>
						</div>

						<h3
							:class="[
								'mt-4 text-mgm-txt-md font-medium',
								campaign.status === 'archived'
									? 'text-foreground-default'
									: 'text-foreground-emphasis',
							]"
						>
							{{ campaign.name }}
						</h3>

						<div
							:class="[
								'mt-4 flex',
								campaign.campaignAdmin.firstname ||
								campaign.campaignAdmin.lastname
									? 'justify-between'
									: 'justify-end',
							]"
						>
							<div
								v-if="
									campaign.campaignAdmin.firstname ||
									campaign.campaignAdmin.lastname
								"
							>
								<span
									class="text-mgm-txt-sm font-normal text-foreground-default"
								>
									{{ $t('generic.invited') }}
								</span>

								<div class="mt-1 flex items-center">
									<BaseAvatar
										:src="campaign.campaignAdmin.profilePictureUrl"
										:alt="`${$t('generic.profilePicture')} ${
											campaign.campaignAdmin.firstname
										} ${campaign.campaignAdmin.lastname}`"
										:fallback="
											campaign.campaignAdmin.firstname?.charAt(0) +
											campaign.campaignAdmin.lastname?.charAt(0)
										"
										size="sm"
									/>

									<span
										:class="[
											'ml-2 text-mgm-txt-sm font-medium',
											campaign.status === 'archived'
												? 'text-foreground-default'
												: 'text-foreground-emphasis',
										]"
									>
										{{
											(campaign.campaignAdmin.firstname
												? `${campaign.campaignAdmin.firstname} `
												: '') +
											(campaign.campaignAdmin.lastname
												? campaign.campaignAdmin.lastname
												: '')
										}}
									</span>
								</div>
							</div>

							<div
								v-if="
									campaign.status !== 'archived' &&
									((campaign.helperStatus === 'invited' &&
										campaign.helpersProfilePicture?.length > 0) ||
										(['left', 'joined'].includes(campaign.helperStatus) &&
											campaign.relations?.length > 0))
								"
							>
								<span
									class="block text-right text-mgm-txt-sm font-normal text-foreground-default"
								>
									{{
										campaign.helperStatus === 'invited'
											? $t('generic.community')
											: 'Relations'
									}}
								</span>

								<BaseAvatar
									class="mt-1 justify-end"
									:group-avatars-urls="campaignAvatars(campaign)"
									size="sm"
									type="group"
									plus
								/>
							</div>
						</div>

						<div
							v-if="campaign.status !== 'archived'"
							class="mt-4 flex items-center"
						>
							<BaseBadge
								v-if="campaign.potentialPoints"
								class="mr-2"
								:text="campaign.potentialPoints"
								size="md"
								icon-type="icon"
								icon-name="star"
							/>

							<BaseBadge
								v-if="campaign.helpersCount"
								:text="`${campaign.helpersCount} helpers`"
								color="purple"
								size="md"
								icon-type="icon"
								icon-name="users"
							/>
						</div>

						<BaseButton
							v-if="
								campaign.helperStatus === 'invited' &&
								campaign.status !== 'archived'
							"
							class="mt-6 w-full"
							:text="$t('helperStatusView.becomeHelper')"
							size="md"
							@click="onJoinCampaign(campaign)"
						/>
					</div>
				</div>
			</div>

			<BaseLoader v-else class="min-h-screen" />
		</div>
	</div>
</template>
